import styles from './RelatedInsight.module.scss';

import { TagPill, DataLink, ResponsiveImage, convertMarkdown } from '@/ui';

import { PageDataTag, RelatedInsightProps } from '@/types';

const RelatedInsight = ({
  slug,
  media,
  heading = '',
  insightTypeReference,
  navigationTag,
  pageTag = [],
  openInNewTab,
  showButton = false,
  buttonText = '',
}: RelatedInsightProps) => {
  let insightName = insightTypeReference?.insightName ?? '';

  const hasImage = !!media?.url;
  const hasInsightType = !!insightTypeReference;
  const hasNavigationTag = !!navigationTag;

  const renderTags = () => {
    if (hasNavigationTag) {
      return <TagPill text={navigationTag.tagText} />;
    }

    if (pageTag.length > 0) {
      return pageTag.map((tag: PageDataTag) => (
        <div key={tag.tagName}>
          <TagPill text={tag.tagName} />
        </div>
      ));
    }

    return null;
  };

  const draft = slug === '#linked_page_unpublished';

  return (
    <div className={draft ? styles.draft : ''}>
      <DataLink
        url={slug}
        className={styles.relatedInsight}
        openInNewTab={openInNewTab}
      >
        {hasImage && (
          <div className={styles.relatedInsight__imageWrapper}>
            <ResponsiveImage
              sizes={{ sm: 400 }}
              className={styles.relatedInsight__image}
              media={media}
            />
          </div>
        )}

        <div className={styles.relatedInsight__content}>
          {(hasInsightType || hasNavigationTag) && (
            <div className={styles.relatedInsight__tags}>
              {hasInsightType && (
                <span className={styles.relatedInsight__tag}>
                  {insightName}
                </span>
              )}
              {renderTags()}
            </div>
          )}

          <div className={styles.relatedInsight__heading}>
            {convertMarkdown(heading)}
          </div>

          {showButton && buttonText && (
            <div className={`${styles.relatedInsight__fauxBtn}`}>
              <span>{buttonText}&nbsp;</span>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z'
                  fill='currentColor'
                ></path>
              </svg>
            </div>
          )}
        </div>
      </DataLink>
    </div>
  );
};

export default RelatedInsight;
