import { DataFeaturedLink } from '@/types';
import RelatedInsight from './RelatedInsight';

type RelatedInsightFromFeaturedLinkProps = {
  featuredLink: DataFeaturedLink;
};

const RelatedInsightFromFeaturedLink = ({
  featuredLink,
}: RelatedInsightFromFeaturedLinkProps) => {
  // Create Random unique key for each Related Insight if no slug is present
  const randomKey = Math.random().toString(36).substring(7);

  let slug = featuredLink.featuredPage?.slug
    ? featuredLink.featuredPage.slug
    : '#linked_page_unpublished';

  return (
    <RelatedInsight
      key={randomKey}
      openInNewTab={!!featuredLink.featuredUrl}
      slug={featuredLink.featuredUrl ?? slug}
      heading={featuredLink.featuredDescription}
      media={featuredLink.featuredImage}
      showButton={featuredLink.featuredShowButton}
      buttonText={featuredLink.featuredButtonText}
    />
  );
};

export default RelatedInsightFromFeaturedLink;
